import { useState, useEffect, useCallback } from 'react';

export const useMouseMove = nodeRef => {
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const onMouseMove = useCallback(event => {
    setMouseX(event.clientX);
    setMouseY(event.clientY);
  }, []);

  const reset = useCallback(() => {
    setMouseY(0);
    setMouseX(0);
  }, []);

  useEffect(() => {
    const node = nodeRef.current;

    if (node) {
      node.addEventListener('mousemove', onMouseMove);
    }

    return () => {
      if (node) {
        node.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [nodeRef, onMouseMove]);

  return { x: mouseX, y: mouseY, reset };
};
