import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export default {
  HorizontalScroller: styled.div`
    overflow: hidden;
  `,
  DesktopContainer: animated(styled.div`
    display: flex;
    justify-content: flex-start;
    overflow-x: visible;
    overflow-y: initial;

    ${({ imageContainer }) =>
      imageContainer &&
      css`
        min-height: 400px;
      `}
  `),
  MobileContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;

    ${({ imageContainer }) =>
      imageContainer &&
      css`
        min-height: 400px;
      `}
  `,
  Wrapper: styled.div`
    position: relative;
    min-height: 400px;
  `,
};
