import styled, { css } from 'styled-components';
import { fontSize } from 'src/modules/text';

export default {
  Nav: styled.nav`
    position: sticky;
    background: white;
    padding: 0 10px;
  `,
  Title: styled.div``,
  Menu: styled.ul`
    display: none;
    align-items: center;
    margin: 0;

    @media only screen and (min-width: 768px) {
      display: flex;
    }
  `,
  MenuItem: styled.li`
    font-size: ${({ selected }) => (selected ? fontSize.xl : fontSize.md)};
    margin-right: 70px;
    cursor: pointer;
    list-style-type: ${({ selected }) => (selected ? 'bullet' : 'none')};
    ${({ selected }) =>
      selected &&
      css`
        min-width: 210px;
      `}
  `,
};
