import React from 'react';
import PropTypes from 'prop-types';

import S from './layout.Nav.Styled';

let scroller = null;
const HEADER_HEIGHT = 135;
if (typeof window !== `undefined`) {
  /* Fix for gatsby build */
  /* eslint-disable global-require */
  const SmoothScroll = require('smooth-scroll');
  scroller = new SmoothScroll(undefined, {
    offset: HEADER_HEIGHT,
  });
}

export const Nav = ({ className, current, menuItems }) => {
  let itemsRotated = menuItems;
  const currentIndex = menuItems.indexOf(current);

  if (currentIndex !== 0) {
    const headItems = menuItems.slice(currentIndex);
    const tailItems = menuItems.slice(0, currentIndex);
    itemsRotated = headItems.concat(tailItems);
  }

  return (
    <S.Nav className={className}>
      <S.Menu>
        {itemsRotated.map(item => (
          <S.MenuItem
            key={item}
            selected={item === current}
            onClick={() => scroller.animateScroll(document.querySelector(`#${item.toLowerCase()}`))}
          >
            {item}
          </S.MenuItem>
        ))}
      </S.Menu>
    </S.Nav>
  );
};

Nav.propTypes = {
  className: PropTypes.string,
  current: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Nav.defaultProps = {
  className: '',
};
