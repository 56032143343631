import styled from 'styled-components';
import { fontSize } from 'src/modules/text';

export default {
  Footer: styled.footer`
    margin-top: 50px;
    padding: 10px;
  `,
  Logo: styled.div`
    margin-bottom: 40px;
    width: 140px;
  `,
  CompanyDetails: styled.div`
    font-size: 2rem;
    @media only screen and (min-width: 600px) {
      font-size: ${fontSize.md};
    }
  `,
};
