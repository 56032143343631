import { createGlobalStyle } from 'styled-components';
import { colors } from 'src/styles';
import { fontSize } from 'src/modules/text';
import { darken } from 'polished';
import './layout.fonts.css';

export const GlobalStyle = createGlobalStyle`

  @keyframes spin {
    0% {
      transform: rotateZ(0);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  html {
    font-size: ${fontSize.base.mobile};
    @media only screen and (min-width: 600px) {
      font-size: ${fontSize.base.desktop};
    }
  }


  body {
    margin: 0;
    color: ${colors.main.black};
    font-family: MaaxMono 
    line-height: 1.25;
    -webkit-font-smoothing: antialiased !important;
    font-size: ${fontSize.xl};

  }

  a {
    &, &:visited {
      color: ${colors.main.blue};
      text-decoration: none;
    }

    &:hover {
      color: ${darken(0.1, colors.main.blue)};
    }
  }

  p {
    margin:0;
  }
`;
