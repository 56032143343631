import React from 'react';
import PropTypes from 'prop-types';

import S from './input.ImageFilter.Styled';

const COLUMNS = 3;

const FiltersColumn = ({ filters, onFilterClick, poolType }) => (
  <S.FiltersColumn>
    {filters.map(filter => (
      <S.Filter key={filter} onClick={() => onFilterClick(filter)}>
        {filter}
        <span style={{ color: filter === poolType ? 'black' : 'white' }}>•</span>
      </S.Filter>
    ))}
  </S.FiltersColumn>
);

FiltersColumn.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterClick: PropTypes.func.isRequired,
  poolType: PropTypes.string.isRequired,
};

export const ImageFilter = ({ filters, onFilterClick, poolType }) => {
  const filtersInColumns = [];
  if (filters.length < 4) {
    filtersInColumns.push(filters);
  } else {
    const itemsPerColumn = Math.ceil(filters.length / COLUMNS);
    for (let i = 0; i < COLUMNS; i += 1) {
      filtersInColumns.push(filters.slice(i * itemsPerColumn, i * itemsPerColumn + itemsPerColumn));
    }
  }

  return (
    <S.ImageFilter>
      {filtersInColumns.map((filterColumn, index) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <FiltersColumn key={index} filters={filterColumn} onFilterClick={onFilterClick} poolType={poolType} />
      ))}
    </S.ImageFilter>
  );
};

ImageFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterClick: PropTypes.func.isRequired,
  poolType: PropTypes.string.isRequired,
};
