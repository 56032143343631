import styled from 'styled-components';
import { HorizontalScroller } from './layout.HorizontalScroller';

export default {
  TextScroller: styled(HorizontalScroller)`
    position: relative;
    margin-bottom: -9px;
    z-index: 2;

    @media only screen and (min-width: 600px) {
      margin-bottom: -13px;
    }
  `,
};

