import React, { useState, useEffect, useLayoutEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
import _ from 'lodash';

import { useMouseMove } from 'src/modules/input';
import { useWindowWidth } from './layout.hooks';
import S from './layout.HorizontalScroller.Styled';

const IS_BUILD = typeof window === 'undefined';
const IS_TOUCH = !IS_BUILD && ('ontouchstart' in window || navigator.msMaxTouchPoints);

const DesktopContainer = ({ children, itemsKey, imageContainer }) => {
  const windowWidth = useWindowWidth();
  const containerRef = useRef();
  const { translate, reset } = useMouseTransform(containerRef, windowWidth);
  const { x } = useSpring({ x: translate, config: { clamp: true, precision: 200 } });

  // When itemsKey changes (newItems) -> reset transform
  useEffect(() => {
    reset();
  }, [reset, itemsKey]);

  return (
    <S.DesktopContainer
      ref={containerRef}
      style={{ transform: x.interpolate(value => `translate3d(${value}px,0,0)`) }}
      imageContainer={imageContainer}
    >
      {children}
    </S.DesktopContainer>
  );
};

DesktopContainer.propTypes = {
  children: PropTypes.node.isRequired,
  itemsKey: PropTypes.string.isRequired,
  imageContainer: PropTypes.bool.isRequired,
};

const MobileContainer = ({ children, itemsKey, imageContainer, width }) => {
  const containerRef = useRef();

  // When itemsKey changes (newItems) -> got to start
  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.scrollLeft = 0;
  }, [itemsKey]);

  return (
    <S.MobileContainer ref={containerRef} imageContainer={imageContainer}>
      {width && <S.Wrapper style={{ width }}>{children}</S.Wrapper>}
      {!width && children}
    </S.MobileContainer>
  );
};

MobileContainer.propTypes = {
  children: PropTypes.node.isRequired,
  itemsKey: PropTypes.string.isRequired,
  imageContainer: PropTypes.bool.isRequired,
  width: PropTypes.number,
};

MobileContainer.defaultProps = {
  width: null,
};

export const HorizontalScroller = ({ children, className, itemsKey, imageContainer, width }) => {
  const [isClientInit, setIsClientInit] = useState(false);
  const windowWidth = useWindowWidth();
  const isMobile = IS_TOUCH && windowWidth < 1024;

  useEffect(() => {
    setIsClientInit(true);
  }, []);

  // if (!isClientInit) return null;

  return (
    <S.HorizontalScroller className={className} imageContainer={imageContainer}>
      {isMobile && (
        <MobileContainer itemsKey={itemsKey} imageContainer={imageContainer} width={width}>
          {children}
        </MobileContainer>
      )}
      {!isMobile && (
        <DesktopContainer itemsKey={itemsKey} imageContainer={imageContainer}>
          {children}
        </DesktopContainer>
      )}
    </S.HorizontalScroller>
  );
};

HorizontalScroller.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  itemsKey: PropTypes.string,
  imageContainer: PropTypes.bool,
  width: PropTypes.number,
};

HorizontalScroller.defaultProps = {
  className: '',
  itemsKey: '#',
  imageContainer: false,
  width: null,
};

/**
 * Returns a bezier interpolated value, using the given ranges
 * @param {number} value  Value to be interpolated
 * @param {number} s1 Source range start
 * @param {number} s2  Source range end
 * @param {number} t1  Target range start
 * @param {number} t2  Target range end
 * @param {number} [slope]  Weight of the curve (0.5 = linear, 0.1 = weighted near target start, 0.9 = weighted near target end)
 * @returns {number} Interpolated value
 */
const interpolate = (inputValue, s1, s2, t1, t2, slope = 0.5) => {
  // If the value is out of the source range, floor to min/max target values
  let value = inputValue;
  if (value < Math.min(s1, s2)) {
    return Math.min(s1, s2) === s1 ? t1 : t2;
  }

  if (value > Math.max(s1, s2)) {
    return Math.max(s1, s2) === s1 ? t1 : t2;
  }

  // Reverse the value, to make it correspond to the target range (this is a side-effect of the bezier calculation)
  value = s2 - value;

  const C1 = { x: s1, y: t1 }; // Start of bezier curve
  const C3 = { x: s2, y: t2 }; // End of bezier curve
  const C2 = {
    // Control point
    x: C3.x,
    y: C1.y + Math.abs(slope) * (C3.y - C1.y),
  };

  // Find out how far the value is on the curve
  const percent = value / (C3.x - C1.x);

  return C1.y * b1(percent) + C2.y * b2(percent) + C3.y * b3(percent);

  function b1(t) {
    return t * t;
  }
  function b2(t) {
    return 2 * t * (1 - t);
  }
  function b3(t) {
    return (1 - t) * (1 - t);
  }
};

function useMouseTransform(nodeRef, windowWidth) {
  const [translate, setTranslate] = useState(0);
  const { x, reset: mouseReset } = useMouseMove(nodeRef);
  const containerWidth = _.get(nodeRef, 'current.scrollWidth', 0);
  const maxTranslate = containerWidth - windowWidth;

  const reset = useCallback(() => {
    setTranslate(0);
    mouseReset();
  }, [mouseReset]);

  const DEAD_ZONE = 50;
  useLayoutEffect(() => {
    const translateNew = interpolate(x, DEAD_ZONE, windowWidth - DEAD_ZONE, 0, maxTranslate, 0.5);
    setTranslate(translateNew);
  }, [x, translate, windowWidth, maxTranslate]);

  return {
    translate: -translate,
    transform: {
      transform: `translate3d(-${translate}px, 0, 0)`,
    },
    reset,
  };
}
