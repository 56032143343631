import React from 'react';
import PropTypes from 'prop-types';

import { GlobalStyle } from './layout.GlobalStyle';
import Head from './layout.Head';
import Footer from './layout.Footer';

export const Layout = ({ children, headOptions, footerOptions }) => {
  return (
    <>
      <GlobalStyle />
      <Head {...headOptions} />
      <main>{children}</main>
      <Footer {...footerOptions} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headOptions: PropTypes.object,
  footerOptions: PropTypes.object,
};

Layout.defaultProps = {
  headOptions: {},
  footerOptions: {},
};

Layout.defaultProps = {};
