import styled from 'styled-components';
import { fontSize } from 'src/modules/text';

export default {
  ImageFilter: styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }
  `,
  FiltersColumn: styled.div`
    display: flex;
    flex-direction: column;
    :not(:last-child) {
      margin-right: 160px;
    }
  `,
  Filter: styled.div`
    font-size: 2rem;
    text-transform: uppercase;
    cursor: pointer;
    @media only screen and (min-width: 600px) {
      font-size: ${fontSize.md};
    }
  `,
};
