import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Head({ meta }) {
  const {
    site,
    markdownRemark: {
      frontmatter: { title, description, keywords },
    },
  } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "metadata" } }) {
          frontmatter {
            title
            description
            keywords
          }
        }

        site {
          siteMetadata {
            title
            metaTitle
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.metaTitle;
  const { author } = site.siteMetadata;

  return (
    <Helmet
      title={`${metaTitle}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: author,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'og:image',
          content: '/uploads/meta-main.png',
        },
      ].concat(meta)}
    />
  );
}

Head.defaultProps = {
  meta: [],
};

Head.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default Head;
