import styled from 'styled-components';

import { breakpoints } from 'src/styles';
import { layoutStyles } from './layout.styles';

export default {
  Section: styled.section`
    padding: 50px 0px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    ${({ withPadding }) => withPadding && layoutStyles.pagePadding}

    @media only screen and (min-width: ${breakpoints.xs.max}) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  `,
};
