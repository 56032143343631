import { Converter } from 'showdown';

const converter = new Converter();

export function convertMDToHTML(mdText) {
  const htmlResult = converter.makeHtml(mdText);
  return htmlResult;
}

export function convertMDToInnerHTML(mdText) {
  return {
    __html: convertMDToHTML(mdText),
  };
}
