import React from 'react';
import PropTypes from 'prop-types';
import S from './text.Heading.Styled';

const Heading = ({ className, as, children }) => {
  return (
    <S.Heading className={className} as={as}>
      {children}
    </S.Heading>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
};

Heading.defaultProps = {
  className: '',
  as: 'h1',
};

export default Heading;
