import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { convertMDToInnerHTML } from 'src/modules/text';
import { useLocalStorage } from 'src/modules/store';
import S from './layout.CookiesBanner.Styled';

export const CookiesBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [cookiesHidden, setCookiesHidden] = useLocalStorage('cookiesHidden', false);

  const {
    markdownRemark: {
      frontmatter: { text },
    },
  } = useStaticQuery(graphql`
    query Banner {
      markdownRemark(frontmatter: { templateKey: { eq: "banner" } }) {
        frontmatter {
          text
        }
      }
    }
  `);

  useEffect(() => {
    if (cookiesHidden === false) setShowBanner(true);
    return () => null;
  }, [cookiesHidden]);

  if (cookiesHidden) return null;
  if (!showBanner) return null;
  return (
    <S.Cookies>
      <S.Container>
        <div dangerouslySetInnerHTML={convertMDToInnerHTML(text)} />
        <S.CloseButton onClick={() => setCookiesHidden(true)}>X</S.CloseButton>
      </S.Container>
    </S.Cookies>
  );
};
