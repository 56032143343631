import styled from 'styled-components';

export default {
  Heading: styled.h1`
    font-size: 28px;
    line-height: 1.19;
    font-weight: bold;
    margin: 0;
  `,
};
