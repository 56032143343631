import styled from 'styled-components';
import { fontSize } from 'src/modules/text';

export default {
  Cookies: styled.div`
    z-index: 3;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 0px;
    border-top: 1px solid black;
    font-size: 2rem;
    line-height: 1.4;
    background: white;
    @media only screen and (min-width: 600px) {
      font-size: ${fontSize.md};
    }
  `,
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding: 10px;
  `,
  CloseButton: styled.div`
    margin-left: 40px;
    margin-right: 20px;
    font-size: 30px;
    cursor: pointer;
  `,
};
