import { css } from 'styled-components';

function pageWrap() {
  return css`
    max-width: 1024px;
    margin: 0 auto;
  `;
}

function pagePadding() {
  return css`
    padding-left: 20px;
    padding-right: 20px;
  `;
}

export const layoutStyles = {
  pageWrap,
  pagePadding,
};
