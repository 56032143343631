import styled from 'styled-components';
import { colors } from 'src/styles';

export default {
  Input: styled.input`
    height: 60px;
    line-height: 60px;
    padding: 20px;
    box-sizing: border-box;
    margin: 25px 0px;
    border-radius: 30px;
    border: 1px solid ${colors.main.grey1};
    font-size: 14px;
    font-weight: bold;
    color: ${colors.main.black};
    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border: 1px solid ${colors.main.blue};
    }

    &::placeholder {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      color: ${colors.main.grey2};
    }
  `,
};
