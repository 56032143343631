import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { convertMDToInnerHTML } from 'src/modules/text';
import logoImage from 'src/assets/images/logo.svg';
import { CookiesBanner } from './layout.CookiesBanner';
import S from './layout.Footer.Styled';

const Footer = () => {
  const {
    markdownRemark: {
      frontmatter: { text },
    },
  } = useStaticQuery(graphql`
    query Footer {
      markdownRemark(frontmatter: { templateKey: { eq: "footer" } }) {
        frontmatter {
          text
        }
      }
    }
  `);

  return (
    <S.Footer>
      <S.Logo>
        <img src={logoImage} alt="logo" />
      </S.Logo>
      <S.CompanyDetails>
        <div dangerouslySetInnerHTML={convertMDToInnerHTML(text)} />
      </S.CompanyDetails>
      <CookiesBanner />
    </S.Footer>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
