import { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const isBuilding = typeof window === 'undefined';

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(!isBuilding && window.innerWidth);

  useEffect(() => {
    const onWindowResize = () => {
      if (!isBuilding) setWindowWidth(window.innerWidth);
    };
    if (!isBuilding) window.addEventListener('resize', onWindowResize);
    return () => {
      if (!isBuilding) window.removeEventListener('resize', onWindowResize);
    };
  });

  return windowWidth;
};

export const useCenterIntersectionObserver = (callback, nodeId) => {
  const onIntersectionObserved = useCallback(callback, []);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          /* Here's where we deal with every intersection */
          if (entry.isIntersecting) {
            onIntersectionObserved();
          }
        });
      },
      { rootMargin: '-50% 0px -50% 0px' },
    );
    const element = document.getElementById(nodeId);
    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [onIntersectionObserved, nodeId]);
};

export const useResizeObserver = ref => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (!ref.current) return () => {};

    const observer = new ResizeObserver(entries => {
      const entry = entries[0];
      const rect = entry.contentRect;
      setWidth(rect.width);
      setHeight(rect.height);
    });
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return { width, height };
};
