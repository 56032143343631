import React from 'react';
import PropTypes from 'prop-types';
import S from './layout.Section.Styled';

export const Section = ({ className, children, backgroundColor, withPadding }) => (
  <S.Section className={className} backgroundColor={backgroundColor} withPadding={withPadding}>
    {children}
  </S.Section>
);

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  withPadding: PropTypes.bool,
};

Section.defaultProps = {
  className: '',
  backgroundColor: 'white',
  withPadding: false,
};
